<template>
  <b-card-text v-if="!editable">
    <span>
      <b style="font-size: 14px">{{ title }}:</b>
      {{name=='paymentDate' ?  dateValue : value}}
    </span>
  </b-card-text>
  <b-col class="mb-1" cols="12" v-else>
    <b-row class="align-items-center">
      <label class="labelStyle">{{ title }}:</label>
      <span>

        <b-form-input
        v-if="name!='paymentDate'"
        @change="handle" v-model="value" />
        <b-form-datepicker
        v-else
        @input="handle"
        v-model="value"
        ></b-form-datepicker>
      </span>
    </b-row>
  </b-col>
</template>

<script>
import { BCardText, BRow, BCol,BFormInput,BFormDatepicker } from "bootstrap-vue";
export default {
  components: {
    BCardText,
    BRow,
    BFormInput,
    BFormDatepicker,
    BCol,
  },
  props: {
    editable:{
        type:Boolean,
        required: true,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    name:{
      type: String,
      required: true,
    },
    dateValue:{
        type: String,
      required: false,
    }
  },
  watch:{
    value:function(val) {
        
    }
  },
  methods:{
    handle(event){
        console.log(event);
        this.$emit("handleChange",this.name,event)
    },
  },
};
</script>
